import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthResponse } from "../../../@types/authorization/authResponse";
import { AuthState } from "../../../@types/authorization/authState";

const initialState: AuthState = {
  isLogin: localStorage.getItem("access_token") ? true : false,
};

const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.isLogin = false;
    },
    login: (state, auth: PayloadAction<AuthResponse>) => {
      state.isLogin = true;
      localStorage.setItem("access_token", auth.payload.access_token);
      localStorage.setItem("refresh_token", auth.payload.refresh_token);
    },
  },
});

export const { logout, login } = authorizationSlice.actions;

export const authorizationActions = {
  logout,
  login,
};

export const authorizationReducer = authorizationSlice.reducer;
