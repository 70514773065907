import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Routes } from "./routes";
import { useTypedSelector } from "./__store/hooks/typedSelector";
import SideBar from "./components/sidebar";
import { BrowserRouter } from "react-router-dom";
import { Container } from "@mui/material";
import { Toast } from "./components/snackbars";

import "./App.css";

function App() {
  const drawerWidth = 240;
  const state = useTypedSelector((s) => s.authorization);

  return (
    <>
      <Toast />
      <BrowserRouter>
        <Box classes="css-1oqqzyl-MuiContainer-root" sx={{ display: "flex" }}>
          {!state.isLogin ? <></> : <SideBar />}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Container>
              <Routes />
            </Container>
          </Box>
        </Box>
      </BrowserRouter>
    </>
  );
}

export default App;
