import { useEffect, useRef, useState } from "react";

import moment from "moment";
import TextField from "@mui/material/TextField";
import {
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";

import {
  getAllStatistic,
  getUsersGrowth,
  getUsersRewards,
} from "../../../api/statistic";
import { ChartData } from "../../../@types/statistic/chartData";
import { Chart } from "../../../components/chart";
import { UsersGrowth } from "../../../@types/statistic/usersGrowth";
import { ReferralType } from "../../../@types/referral-type/referralType";
import { SelectObject } from "../../../components/filters/types/selectObject";
import { getReferralTypes } from "../../../api/referral";
import { RewardsChart } from "../../../@types/statistic/rewardChart";
import { Statistic } from "../../../@types/statistic/statistic";
import { useTypedSelector } from "../../../__store/hooks/typedSelector";
import { useActions } from "../../../__store/hooks/useAction";

import "./index.css";

export const Statistics = () => {
  const state = useTypedSelector((s) => s.statistic);
  const {
    setRewardsFromDate,
    setRewardsToDate,
    setUsersFromDate,
    setUsersToDate,
    setStatisticFromDate,
    setStatisticToDate,
    setStatisticTypeId,
    setRewardsTypeId,
    setUsersTypeId,
    setIsActive,
  } = useActions();

  const [usersData, setData] = useState<ChartData>();
  const [rewardsData, setRewardsChart] = useState<ChartData>();
  const [selectCollection, setSelectCollection] = useState<SelectObject[]>();
  const screenRef = useRef<any>();
  const [statistic, setStatistic] = useState<Statistic>();

  useEffect(() => {
    loadUsersGrowth();
    loadTypes();
    loadRewards();
    loadAllStatistic();
  }, [state]);

  const initData = (usersGrowth: UsersGrowth) => {
    if (usersGrowth.dates) {
      const datasets = {
        labels: initLabels(usersGrowth.dates.map((item) => item)),
        datasets: [
          initDataSets(
            "Активные",
            usersGrowth?.activeUsers.map((item) => item.countOnDate),
            "green"
          ),
          initDataSets(
            "Не активные",
            usersGrowth?.notActiveUsers.map((item) => item.countOnDate),
            "red"
          ),
        ],
      };
      setData(datasets);
    }
  };

  const loadUsersGrowth = () => {
    const query = `from=${state.usersDateFrom.toUTCString()}&to=${state.usersDateTo.toUTCString()}&referralTypeId=${
      state.usersTypeId
    }`;
    getUsersGrowth(query).then((user) => {
      initData(user.data as UsersGrowth);
    });
  };

  const loadTypes = () => {
    getReferralTypes().then((types) => {
      const refTypes: ReferralType[] = types.data || [];
      const mapTypes: SelectObject[] = refTypes
        ? refTypes.map((s) => ({ value: s.id, label: s.name }))
        : [];
      setSelectCollection(mapTypes);
    });
  };

  const initDataSets = (label: string, data: number[], color: string) => {
    return {
      label: label,
      data: data.map((item) => item),
      borderColor: color,
      backgroundColor: color,
    };
  };

  const initLabels = (labels: Date[]) =>
    labels.map((item) => moment(item).format("DD MMM, YYYY"));

  const initRewardsChart = (data: RewardsChart) => {
    if (data) {
      const datasets = {
        labels: initLabels(data.rewards.map((item) => item.date)),
        datasets: [
          initDataSets(
            "",
            data.rewards.map((item) => item.value),
            "blue"
          ),
        ],
      };
      setRewardsChart(datasets);
    }
  };

  const loadRewards = () => {
    const query = `from=${state.rewardsDateFrom.toUTCString()}&to=${state.rewardsDateTo.toUTCString()}&referralTypeId=${
      state.rewardsTypeId
    }`;
    getUsersRewards(query).then((reward) => {
      initRewardsChart(reward.data as RewardsChart);
    });
  };

  const loadAllStatistic = () => {
    const query = `?isActive=${state.isActive}&referralTypeId=${
      state.statisticTypeId
    }&dateFrom=${state.statisticDateFrom.toUTCString()}&dateTo=${state.statisticDateTo.toUTCString()}`;
    getAllStatistic(query).then((statistic) => {
      setStatistic(statistic.data as Statistic);
    });
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography className="title" variant="h5" gutterBottom component="div">
          <div className="title">Статистика</div>
        </Typography>
      </Breadcrumbs>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Card sx={{ maxWidth: "100%", m: "1%" }}>
                <div className="statistic-title">Показатели</div>
                <CardContent className="all-statistic">
                  <div className="all-statistic-item">
                    <div>Всего пользователей:</div>
                    <div>{statistic?.totalUsers}</div>
                  </div>
                  <hr className="hr" />
                  <div className="all-statistic-item">
                    <div>Сред. кол-во юзеров 1 уровня:</div>
                    <div> {statistic?.avgRefferals}</div>
                  </div>
                  <hr className="hr" />
                  <div className="all-statistic-item">
                    <div>Средняя сумма выплат:</div>
                    <div>{statistic?.avgRewardSum} ZAM</div>
                  </div>
                  <hr className="hr" />
                  <div className="all-statistic-item">
                    <div>Всего выплачено:</div>
                    <div>{statistic?.rewardSum} ZAM</div>
                  </div>
                </CardContent>

                <CardActions>
                  <div className="statistic-card-actions">
                    <DesktopDatePicker
                      label="От"
                      inputFormat="dd/MM/yyyy"
                      value={state.statisticDateFrom}
                      onChange={(e) => setStatisticFromDate(e as Date)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          className="date-picker-field"
                          {...params}
                        />
                      )}
                    />
                    <DesktopDatePicker
                      label="До"
                      inputFormat="dd/MM/yyyy"
                      value={state.statisticDateTo}
                      onChange={(e) => setStatisticToDate(e as Date)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          className="date-picker-field"
                          {...params}
                        />
                      )}
                    />
                    <TextField
                      select
                      id="referralTypeId"
                      name="referralTypeId"
                      label="Тип"
                      size="small"
                      value={state.statisticTypeId}
                      onChange={(e) => setStatisticTypeId(e.target.value)}
                      helperText="Выберите"
                    >
                      {selectCollection &&
                        selectCollection.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      <MenuItem key="" value="">
                        Не выбрано
                      </MenuItem>
                    </TextField>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={state.isActive}
                          onChange={(_) => setIsActive(!state.isActive)}
                        />
                      }
                      label="Акт/не активн."
                    />
                  </div>
                </CardActions>
              </Card>
            </LocalizationProvider>
          </div>
          <div className="charts">
            <div className="chart-item">
              <Chart
                chartName="Прирост пользователей"
                data={usersData!}
                from={state.usersDateFrom}
                to={state.usersDateTo}
                setFrom={setUsersFromDate}
                setTo={setUsersToDate}
                ref={screenRef}
              >
                <TextField
                  select
                  id="referralTypeId"
                  name="referralTypeId"
                  label="Тип"
                  size="small"
                  value={state.usersTypeId}
                  onChange={(e) => setUsersTypeId(e.target.value)}
                  helperText="Выберите значение"
                >
                  {selectCollection &&
                    selectCollection.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  <MenuItem key="" value="">
                    Не выбрано
                  </MenuItem>
                </TextField>
              </Chart>
            </div>
            <div className="chart-item">
              <Chart
                chartName="Сумма выплаченных бонусов"
                data={rewardsData!}
                from={state.rewardsDateFrom}
                to={state.rewardsDateTo}
                setFrom={setRewardsFromDate}
                setTo={setRewardsToDate}
                ref={screenRef}
              >
                <TextField
                  select
                  id="referralTypeId"
                  name="referralTypeId"
                  label="Тип"
                  value={state.rewardsTypeId}
                  size="small"
                  onChange={(e) => setRewardsTypeId(e.target.value)}
                  helperText="Выберите значение"
                >
                  {selectCollection &&
                    selectCollection.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  <MenuItem key="" value="">
                    Не выбрано
                  </MenuItem>
                </TextField>
              </Chart>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
