import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSystemState } from "../../../@types/settings/reduxSystemState";

const initialState: ReduxSystemState = {
  isOpen: false,
  message: "Действие успершно завершено",
  severity: "success",
};

const systemStateSlice = createSlice({
  name: "systemState",
  initialState,
  reducers: {
    showToast: (state, toastState: PayloadAction<ReduxSystemState>) => {
      state.isOpen = true;
      state.message = toastState.payload.message;
      state.severity = toastState.payload.severity;
    },
    closeToast: (state) => {
      state.isOpen = false;
    },
  },
});

export const { showToast, closeToast } = systemStateSlice.actions;

export const systemStateActions = {
  showToast,
  closeToast,
};

export const systemStateReducer = systemStateSlice.reducer;
