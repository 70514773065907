import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { UsersModel } from "../../../@types/users/ssoUser";
import { getAll, post } from "../../../api/users/index";
import Pagination from "../../../components/pagination/pagination";
import { paginationSettings } from "../../../constants/pagination";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { Filters } from "../../../components/filters";
import { useActions } from "../../../__store/hooks/useAction";
import { ReduxSystemState } from "../../../@types/settings/reduxSystemState";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  justifyContent: "center",
  display: "flex",
  boxShadow: "none",
  marginTop: "2%",
}));

export const Users = () => {
  const [users, setUsers] = useState<UsersModel>();
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>();
  const [queryParameters, setQueryParameters] = useState<string>("");
  const { showToast } = useActions();

  useEffect(() => {
    getAllUsers();
  }, [page, queryParameters]);

  function getAllUsers() {
    getAll(page, paginationSettings.take, queryParameters).then((res) => {
      if (res) {
        setUsers(res.data as UsersModel);
        setTotalCount((res.data as UsersModel).usersCount);
      }
    });
  }

  function setReferralUser(phoneNumber: string, id: string) {
    let toastState: ReduxSystemState = {
      message: "",
      severity: "success",
    };
    post({ phoneNumber, invitedUserId: id })
      .then((res) => {
        if (res) {
          toastState.message =
            "Пользователь успешно добавлен в реферальную программу: " +
            res.data;
        }
      })
      .catch((error) => {
        toastState.message = "Ошибка: " + error.data;
        toastState.severity = "error";
      });
      showToast(toastState)
  }

  const changePage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography className="title" variant="h5" gutterBottom component="div">
          <div className="title">Все пользователи</div>
        </Typography>
      </Breadcrumbs>
      <Filters
        searchId="phoneNumber"
        searchLabel="Телефон"
        isSearch={true}
        updateFilters={setQueryParameters}
      />
      <Card sx={{ minWidth: 300 }}>
        <CardContent>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={4}>
              ФИО
            </Grid>
            <Grid className="media" item xs={1}>
              Телефон
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <List>
            {users &&
              users.users?.map((user) => (
                <ListItem disablePadding key={user.id}>
                  <ListItemButton>
                    <Grid
                      justifyContent="space-between"
                      container
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        {user.lastName} {user.firstName} {user.middleName}
                      </Grid>
                      <Grid className="media" item xs={2}>
                        {user.phoneNumber}
                      </Grid>
                      <Grid display="flex" justifyContent="end" item xs={1}>
                        <IconButton
                          color="primary"
                          aria-label="edit status"
                          component="span"
                          onClick={() =>
                            setReferralUser(user.phoneNumber, user.id)
                          }
                        >
                          <StarOutlinedIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <ListItemText />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </CardContent>
      </Card>
      {totalCount && (
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <Item>
              <Pagination
                page={page}
                totalCount={totalCount}
                changePage={changePage}
              />
            </Item>
          </Stack>
        </Box>
      )}
    </>
  );
};
